/*
 * @Author: dingguowei
 * @Date: 2023-06-07 16:49:57
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-06-29 11:25:50
 */
import request from '@/plugins/axios'
const base = '/'

// 品牌方查询审核子任务
export function getaudittask(data) {
    return request({
      url:  base + 'api/zbMissionReceive/brand/page',
      method: 'post',
      data
    })
}

// 品牌方查询审核任务各类别数量
export function queryQuantiy(params) {
    return request({
      url:  base + 'api/zbMissionReceive/brand/stat',
      method: 'get',
      params
    })
}

// 品牌方查询提交任务时间线
export function gettimeline(data) {
    return request({
      url:  base + 'api/zbMissionRecord/brand/detail',
      method: 'post',
      data
    })
}


// 品牌方永久拒绝该用户
export function blocktask(data) {
  return request({
    url:  base + 'api/zbMissionReceive/brand/forbid',
    method: 'post',
    data
  })
}

// 品牌方拒绝该用户
export function refusetask(data) {
  return request({
    url:  base + 'api/zbMissionReceive/brand/refuse',
    method: 'post',
    data
  })
}

// 品牌方通过该用户
export function passtask(data) {
  return request({
    url:  base + 'api/zbMissionReceive/brand/judge',
    method: 'post',
    data
  })
}

// 品牌方获取用户提交内容
export function getresult(data) {
  return request({
    url:  base + 'api/zbMissionReceive/get',
    method: 'post',
    data
  })
}

// 管理端查询所有社交信息数据
export function getsocialData(data) {
  return request({
    url:  base + 'api/sysUserSocialVerify/page',
    method: 'post',
    data
  })
}


// 管理端通过社交信息数据
export function passsocial(data) {
  return request({
    url:  base + 'api/sysUserSocialVerify/judge',
    method: 'post',
    data
  })
}

// 管理端拒绝社交信息数据
export function refusesocial(data) {
  return request({
    url:  base + 'api/sysUserSocialVerify/refuse',
    method: 'post',
    data
  })
}
// 管理端查看笔记数据
export function getnoteData(data) {
  return request({
    url:  base + 'api/zbMissionAnnex/page',
    method: 'post',
    data
  })
}

// 管理端查看笔记数据
export function getnoteTotal(data) {
  return request({
    url:  base + 'api/zbMissionAnnex/total',
    method: 'post',
    data
  })
}

// 管理端导出笔记数据
export function exportnoteData(data) {
  return request({
    url:  base + 'api/zbMissionAnnex/export',
    method: 'post',
    data
  })
}

// 管理端导出笔记数据
export function exportnoteDatabyid(data) {
  return request({
    url:  base + 'api/zbMissionAnnex/appoint/export',
    method: 'post',
    data
  })
}



// 管理端新增笔记数据
export function addnoteData(data) {
  return request({
    url:  base + 'api/zbMissionAnnex/save',
    method: 'post',
    data
  })
}

// 管理端刷新该任务下的笔记数据
export function renewnoteData(params) {
  return request({
    url:  base + 'api/zbMissionAnnex/renew',
    method: 'get',
    params
  })
}

// 删除笔记
export function deletenoteData(data) {
  return request({
    url:  base + 'api/zbMissionAnnex/del',
    method: 'post',
    data
  })
}

// 更新笔记
export function updatenoteData(data) {
  return request({
    url:  base + 'api/zbMissionAnnex/update',
    method: 'post',
    data
  })
}

// 获取笔记历史数据
export function getnotehistroy(data) {
  return request({
    url:  base + 'api/zbAnnexRecord/get',
    method: 'post',
    data
  })
}

// 获取密令词统计
export function getsecretstat(data) {
  return request({
    url:  base + 'api/jd/secret/get',
    method: 'get',
    data
  })
}

// 获取京东订单数据
export function getjdorder(data) {
  return request({
    url:  base + 'api/jd/orderId/get',
    method: 'get',
    data
  })
}


















