<!-- 社交信息审核 -->
<template>
  <div class="social">
    <el-card>
      <el-table
        :data="socialData"
        :key="tableKey"
        style="width: 100%; font-size: 0.8rem; flex: 1"
        height="100%"
        v-loading="loading"
      >
        <el-table-column
          label="序号"
          type="index"
          width="100"
          align="center"
          :show-overflow-tooltip="true"
        >
        </el-table-column>

        <el-table-column
          label="用户名"
          :show-overflow-tooltip="true"
          width="100"
        >
          <template slot-scope="scope">
            {{ scope.row.userAccount }}
          </template>
        </el-table-column>
        <el-table-column
          label="审核状态"
          :show-overflow-tooltip="true"
          width="120"
        >
          <template slot-scope="scope">
            {{ statusText(scope.row.status) }}
          </template>
        </el-table-column>
        <el-table-column label="批注" :show-overflow-tooltip="true" width="180">
          <template slot-scope="scope">
            {{ scope.row.endorse ? scope.row.endorse : "暂无" }}
          </template>
        </el-table-column>
        <el-table-column label="个人主页" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <a :href="scope.row.homepage" target="_blank" style="width: 100%">
              {{ scope.row.homepage }}</a
            >
          </template>
        </el-table-column>
        <!-- <el-table-column label="个人主页截图" :show-overflow-tooltip="true" width="120">
          <template slot-scope="scope">
            <el-image style="width: 100px; height: 100px" :src="scope.row.homepageScreenshotList[0]"
              :preview-src-list="scope.row.homepageScreenshotList">
            </el-image>
          </template>
        </el-table-column> -->
        <el-table-column
          label="操作"
          :show-overflow-tooltip="true"
          width="200"
          align="center"
        >
          <template slot-scope="scope">
            <div class="btn" v-if="scope.row.status === 0">
              <el-popconfirm
                title="确定要通过吗？"
                style="margin-right: 10px"
                @confirm="pass(scope.row.id)"
              >
                <el-button slot="reference" size="mini" type="success" round
                  >通过</el-button
                >
              </el-popconfirm>
              <el-popconfirm
                title="确定要拒绝吗？"
                @confirm="refuse(scope.row.id)"
              >
                <el-button slot="reference" size="mini" type="warning" round
                  >拒绝</el-button
                >
              </el-popconfirm>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      style="margin-left: AUTO; margin-top: 10px; display: block"
      :pager-count="7"
      @current-change="getsocialData"
      :current-page.sync="pageNo"
      :page-size="pageSize"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getsocialData, passsocial, refusesocial } from "@/api/audit";
export default {
  components: {},
  data() {
    return {
      socialData: [],
      pageNo: 1,
      pageSize: 10,
      tableKey: true,
      loading: false,
      total: 0,
    };
  },
  created() {},
  mounted() {
    this.getsocialData();
  },
  watch: {},
  computed: {
    statusText() {
      return function (val) {
        if (val === 0) {
          return "待审核";
        } else if (val === 1) {
          return "审核通过";
        } else if (val === 2) {
          return "被拒绝";
        }
      };
    },
  },
  methods: {
    // 初始化获取社交信息数据
    async getsocialData() {
      await getsocialData({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      }).then((res) => {
        this.socialData = res.data.records;
        this.tableKey = !this.tableKey;
        this.total = res.data.total;
      });
    },
    // 通过操作
    async pass(val) {
      await passsocial({
        id: val,
      }).then((res) => {
        if (res.code === 1000) {
          this.getsocialData();
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    // 拒绝操作
    async refuse(val) {
      await refusesocial({
        id: val,
      }).then((res) => {
        if (res.code === 1000) {
          this.getsocialData();
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
// @import "@/style/common.scss";
.social {
  padding: 1rem 2rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  ::v-deep .el-card {
    flex: 1;
    height: 100%;
    overflow: auto;
  }

  ::v-deep .el-card__body {
    padding: 0;
    height: 100%;
    overflow: auto;
  }

  .btn {
    ::v-deep .el-button--success:focus,
    .el-button--success:hover {
      background-color: #85ce61 !important;
    }

    ::v-deep .el-button--warning:focus,
    .el-button--warning:hover {
      background-color: #ebb563 !important;
    }
  }
}
</style>
<style >
.el-image-viewer__img {
  max-width: 80% !important;
  max-height: 80% !important;
}

.el-button--primary:focus,
.el-button--primary:hover {
  background-color: #66b1ff !important;
}
</style>